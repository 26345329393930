/* Core CSS required for Ionic components to work properly */
@import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/react/css/normalize.css";
@import "@ionic/react/css/structure.css";
@import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/react/css/padding.css";
@import "@ionic/react/css/float-elements.css";
@import "@ionic/react/css/text-alignment.css";
@import "@ionic/react/css/text-transformation.css";
@import "@ionic/react/css/flex-utils.css";
@import "@ionic/react/css/display.css";

@import "./utils";

.calendar-popover {
  --width: auto;
  --max-width: calc(100vw - 10px);
}

.select-popover {
  --width: max-content;
  --min-width: 200px;
  --max-width: calc(100vw - 10px);

  .select-interface-option {
    font-size: inherit;
  }

  &.item.sc-ion-label-ios-h,
  .item .sc-ion-label-ios-h {
    white-space: normal !important;
  }
}

.md button.alert-button.alert-button-confirm {
  text-transform: capitalize;
  font-size: 16px;
}

.md button.alert-button.alert-button-cancel {
  text-transform: capitalize;
  font-size: 16px;
}

.appearance {
  animation: fadeIn 0.5s linear;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

:root {
  ion-searchbar {
    --border-radius: 30px;
    --color: var(--ion-color-primary);
    --icon-color: var(--ion-color-primary);
  }
}
